<template>
  <div class="fill-width">
    <template v-if="custom">
      <validation-observer ref="CUSTOM_FORM" tag="div">
        <v-row>
          <v-col cols="12" class="py-0">
            <validation-provider name="Nombre" vid="customForm.nombre" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="customForm.nombre"
                filled
                dense
                class="expand-search"
                background-color="grey lighten-5"
                placeholder=""
                :error-messages="errors[0]"
              >
                <template v-slot:prepend-inner>
                  <span class="blue-grey--text text--lighten-2 pr-2 pt-1">
                    Nombre de Contacto:
                  </span>
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="py-0 d-flex">
            <validation-provider name="Telefono" vid="customForm.telefono" rules="required|numeric|digits:10" v-slot="{ errors }" tag="div" class="fill-width">
              <v-text-field
              v-model="customForm.telefono"
                filled
                dense
                class="expand-search"
                background-color="grey lighten-5"
                placeholder="Ej. 4140000000"
                :error-messages="errors[0]"
              >
                <template v-slot:prepend-inner>
                  <span class="blue-grey--text text--lighten-2 pr-2 pt-1">
                    Teléfono:
                  </span>
                </template>
              </v-text-field>
            </validation-provider>
            <v-btn
              color="icono"
              class="py-0 ml-2"
              :class="{'px-2': $vuetify.breakpoint.smAndDown}"
              dark
              small
              depressed
              style="height:40px !important; "
              @click="addCustomContact"
            >
              <v-icon
                size="24"
                v-text="'mdi-account-plus'"
              />
            </v-btn>

          </v-col>
        </v-row>
      </validation-observer>
    </template>
    <v-autocomplete
      v-else
      v-model="selectCli"
      :items="items"
      :disabled="loading"
      hide-details
      filled
      class="expand-search"
      no-data-text="No hay registros que coincidan"
      background-color="grey lighten-5"
      placeholder=""
      multiple
      item-text="cli_des"
      return-object
    >
      <!-- v-bind="attrs"
      v-on="on"
      @input="showList = true" -->
        <template v-slot:prepend-inner>
          <span class="blue-grey--text text--lighten-2 pr-2 pt-1">
            Enviar:
          </span>
        </template>
        <template v-slot:append>
          <v-icon v-if="items.length > 0" color="blue-grey lighten-3" size="22">mdi-chevron-down</v-icon>
          <v-progress-circular
            v-if="loading"
            indeterminate
            :width="3"
            color="primary"
          />
        </template>
         <template v-slot:selection=""></template>
        <template v-slot:item="data">
          <v-list-item-icon class="mr-3">
            <v-avatar
              color="icono"
              size="30"
            >
              <span class="font-weight-bold white--text">
                {{ data.item.cli_des | fisrtLetter }}
              </span>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold blue-grey--text"
              v-html="data.item.cli_des"
            />
            <v-list-item-subtitle
              class="muted-text font-italic"
              v-html="data.item.telefonos"
            />
          </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>

</template>
<script>
const CUSTOM_DEFAULT = {
  nombre: '',
  telefono: '',
}

export default {
  name: 'SearchClient',
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    search: '',
    customForm: {...CUSTOM_DEFAULT},
    showList: false,
    selectCli: [],
    headers: [
      { text: '', value: 'data-table-select', sortable: false},
      { text: '', value: 'co_cli', align: ' d-none' },
      { text: 'Seleccionar Todos', value: 'cli_des', sortable: false },
      { text: '', value: 'telefono', align: ' d-none' },
    ],
  }),
  watch: {
    selectCli (val, oldVal) {
      this.$emit('input', val)
    },
    value (val, oldVal) {
      this.selectCli = val
    },
  },
  filters: {
    fisrtLetter(val) {
      return val !== null ? val.toUpperCase().charAt(0) : ''
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(selected) {
        this.$emit('input', selected)
      },
    }
  },
  methods: {
    async addCustomContact(){
      const valid = await this.$refs.CUSTOM_FORM.validate()
      if(valid){
        this.selected.push({
          cli_des: this.customForm.nombre,
          telefonos: this.customForm.telefono,
          co_cli: 0
        })
        this.customForm = {...CUSTOM_DEFAULT}
        this.$refs.CUSTOM_FORM.reset()
      }
    }
  }
}
</script>
<style>
  .data-search .v-data-table__wrapper table tbody tr td:first-child {
    width: 20px !important;
  }
  .data-search .v-data-table__wrapper table tbody tr td .v-icon {
    color:#003b49 !important
  }
</style>
